import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAPI, useBackAction, useInterval, usePoller, useTitle } from 'hooks';
import { getRandomUuid } from 'utils/uuid';
import { resizeBase64Image } from 'utils/images';
import { refreshChat, selectChats, sendMessage, updateChat, updateMembership /* TODO: selectChat */ } from 'slices/chats';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  AddAPhotoOutlined as AddAPhotoOutlinedIcon,
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  PersonAdd as PersonAddIcon,
  Refresh as RefreshIcon,
  SentimentSatisfiedOutlined as SentimentSatisfiedOutlinedIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { TextareaAutosize } from '@mui/base';
import EmojiPicker from 'emoji-picker-react';
import { ChatIcon, ChatLogo, Messages } from 'components';
import { getChatDisplayName } from 'utils/chats';

const fakeItems = new Array(20).fill().map((i) => {
  const item = (
    <Skeleton
      variant="text"
      sx={{
        bgcolor: 'rgba(128, 128, 128, 0.2)',
        display: 'inline-block',
        width: Math.random() * 100 + 50,
      }}
    />
  );

  return { fake: true, id: i, text: item, membership: {} };
});

const ChatMessagesPage = () => {
  const dispatch = useDispatch();
  const { chatId } = useParams();
  const chats = useSelector(selectChats);
  const chat = chats?.find(c => c.id === chatId);
  // const [messages, setMessages] = useState(chat?.last_messages);
  const messages = chat?.last_messages;
  const sendingMessages = chat?.sendingMessages || [];
  const [isEmojiDialogOpen, setIsEmojiDialogOpen] = useState(false);
  const [chatName, setChatName] = useState(null);
  const [chatCustomPhotoUrl, setChatCustomPhotoUrl] = useState(null);
  const [chatPhotoUrl, setChatPhotoUrl] = useState(null);
  const [memberPhotoUrl, setMemberPhotoUrl] = useState(null);
  const [isChatDialogOpen, setIsChatDialogOpen] = useState(false);
  const [isMembershipDialogOpen, setIsMembershipDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(false);
  // const sendingMessagesRef = useRef([]);
  const textareaRef = useRef();
  const memberNameFieldRef = useRef();
  const chatNameFieldRef = useRef();

  const { ChatMembershipsAPI, ChatsAPI, ConnectionsAPI, MessagesAPI } = useAPI();

  useEffect(() => {
    if (!chat) return;

    // setMessages(chat?.last_messages);

    if (!chat.name && chat.type === 'group') {
      setIsChatDialogOpen(true);
    }

    if (!chat.membership.name && !['channel', 'personal', 'support'].includes(chat.type)) {
      setIsMembershipDialogOpen(true);
    }
  }, [chat]);

  useBackAction(({ navigate }) => {
    navigate('/', { replace: true });
  });

  const submitMessage = () => {
    const text = textareaRef.current.value;
    textareaRef.current.value = '';
    setIsSubmitButtonVisible(false);
    dispatch(sendMessage([MessagesAPI, { chatId, text }]));
  };

  const handleTextareaKeyDown = (event) => {
    if (!event.shiftKey && event.keyCode === 13) {
      event.preventDefault();
      submitMessage();
    }
  }

  // const refreshChat = async () => {
  //   const newMessages = await ChatsAPI.get(chatId);
  //   const messageIds = newMessages.map((message) => message.id);
  //   const newSendingMessages = sendingMessagesRef.current.filter((sendingMessage) => (
  //     !messageIds.includes(sendingMessage.id)
  //   ));
  //   setMessages(newMessages);
  //   sendingMessagesRef.current = newSendingMessages;
  // }

  useEffect(() => {
    dispatch(refreshChat([ChatsAPI, chatId]));
  }, [chatId]);

  useInterval(() => {
    dispatch(refreshChat([ChatsAPI, chatId]));
  }, [chatId], 5000);

  // usePoller(refreshChat, [chatId], 500); //

  // console.log('ChatMessages page rerender')

  useTitle(chat ? getChatDisplayName({ ...chat, name: chatName || chat.name }) : '');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyAndNotify = (text) => {
    navigator.clipboard.writeText(text);
    alert('Пригласительная ссылка скопирована');
  };

  const generateInvitation = async (id) => {
    // const url = `https://s3.amazonaws.com/i.i/i.html#/join/${id}`;
    const url = `https://in6.to/#/join/${id}`;
    const title = 'Приглашаю в Protected Space';

    const fallbackMessage = [title, url].join('\n');

    if ('share' in navigator) {
      const dataToShare = { title, url };

      if (navigator.canShare(dataToShare)) {
        try {
          await navigator.share(dataToShare);
        } catch (e) {}
      } else {
        copyAndNotify(fallbackMessage);
      }
    } else {
      copyAndNotify(fallbackMessage);
    }
  };

  const handleInviteMember = async () => {
    const id = getRandomUuid();
    ConnectionsAPI.create({ id, invite_to_chat_id: chatId });

    await generateInvitation(id);
  }

  const reinviteMember = async () => {
    chat?.pending_connection && await generateInvitation(chat.pending_connection.id);
  };

  const deleteInvitation = async () => {
    if (!chat?.pending_connection) return;

    ConnectionsAPI.destroy(chat.pending_connection);
    window.history.back();
  };

  const applyChatSettings = () => {
    const name = chatNameFieldRef.current.value;
    if (!name) return;

    dispatch(updateChat([ChatsAPI, {
      id: chatId,
      name,
      photoUrl: chatPhotoUrl || chatCustomPhotoUrl || chat?.photo_url,
    }]));

    setChatName(name);
    setChatCustomPhotoUrl(chatPhotoUrl);
    setChatPhotoUrl(null);
    setIsChatDialogOpen(false);
  };

  const applyMembershipSettings = () => {
    if (!memberNameFieldRef.current.value) return;

    dispatch(updateMembership([ChatMembershipsAPI, {
      chatId,
      name: memberNameFieldRef.current.value,
      photoUrl: memberPhotoUrl || chat?.membership.photo_url,
    }]));

    setIsMembershipDialogOpen(false);
    setMemberPhotoUrl(null);
  };

  const handleMemberPhotoAttach = ({ target: { files } }) => {
    if (!files.length) return;

    const reader = new FileReader();
    reader.addEventListener('load', async ({ target: { result: fileBody } }) => {
      setMemberPhotoUrl(await resizeBase64Image(fileBody))
    });
    reader.readAsDataURL(files[0]);
  };

  const handleChatPhotoAttach = ({ target: { files } }) => {
    if (!files.length) return;

    const reader = new FileReader();
    reader.addEventListener('load', async ({ target: { result: fileBody } }) => {
      setChatPhotoUrl(await resizeBase64Image(fileBody))
    });
    reader.readAsDataURL(files[0]);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundImage: 'none',
            backgroundColor: 'var(--tg-theme-bg-color)',
            boxShadow: '0px 0px 1px 0px rgb(0 0 0)',
            color: 'var(--tg-theme-button-text-color)',
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => window.history.back()}
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <ChatLogo
              {...{
                ...chat,
                photo_url: chatCustomPhotoUrl || chat?.photo_url,
              }}
              size={40}
              sx={{
                marginRight: '10px',
                '& svg': {
                  backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                  borderColor: 'var(--tg-theme-secondary-bg-color)',
                },
              }}
            />
            <ChatIcon type={chat?.type} />
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {chat && getChatDisplayName({ ...chat, name: chatName || chat.name })}
            </Typography>
            {chat && !['channel', 'personal', 'support'].includes(chat.type) && (
              <IconButton
                color="inherit"
                edge="end"
                onClick={open ? handleClose : handleMenuOpen}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {chat && chat?.type !== 'channel' && (
          <>
            <MenuItem onClick={() => setIsMembershipDialogOpen(true)}>
              <ListItemIcon>
                <Avatar src={chat?.membership.photo_url} />
              </ListItemIcon>
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', }}>
                {chat?.membership.name}
              </span>
            </MenuItem>
            <Divider />
          </>
        )}
        {chat?.type === 'group' && (
          <MenuItem onClick={() => setIsChatDialogOpen(true)}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            Настройки
          </MenuItem>
        )}
        {chat?.type === 'group' && (
          <MenuItem onClick={handleInviteMember}>
            <ListItemIcon>
              <PersonAddIcon fontSize="small" />
            </ListItemIcon>
            Пригласить участника
          </MenuItem>
        )}
        {chat?.type === 'direct' && chat?.pending_connection && (
          <MenuItem onClick={reinviteMember}>
            <ListItemIcon>
              <RefreshIcon fontSize="small" />
            </ListItemIcon>
            Повторить приглашение
          </MenuItem>
        )}
        {chat?.type === 'direct' && chat?.pending_connection && (
          <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Удалить приглашение
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Удалить приглашение
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы точно хотите удалить приглашение?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteDialogOpen(false)}
          >
            Отмена
          </Button>
          <Button
            autoFocus
            color="error"
            onClick={deleteInvitation}
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
      <Messages
        chatId={chatId}
        items={messages ? [...messages, ...sendingMessages] : fakeItems}
        lastReadMessageId={messages ? chat?.last_read_message_id : null}
        lastViewedMessageId={messages ? chat?.last_viewed_message_id : null}
      />
      {chat?.type != 'channel' && (
        <div className="message-form">
          <Dialog
            open={isEmojiDialogOpen}
            onClose={() => setIsEmojiDialogOpen(false)}
          >
            <EmojiPicker
              autoFocusSearch={false}
              emojiStyle="native"
              onEmojiClick={({ emoji }) => {
                if (textareaRef.current) {
                  textareaRef.current.value += emoji;
                }
                setIsEmojiDialogOpen(false);
              }}
              searchDisabled={true}
              skinTonesDisabled={true}
              height="100%"
              width="100%"
            />
          </Dialog>
          <div className="message-form__submit-wrapper">
            <button
              className="message-form__submit"
              onClick={() => setIsEmojiDialogOpen(true)}
              type="button"
            >
              <SentimentSatisfiedOutlinedIcon
                fontSize="medium"
                sx={{ color: 'var(--tg-theme-hint-color)' }}
              />
            </button>
          </div>
          <TextareaAutosize
            className="message-form__textarea"
            maxRows={6}
            onKeyDown={handleTextareaKeyDown}
            onChange={({ target: { value } }) => setIsSubmitButtonVisible(!!value.trim())}
            placeholder="Написать сообщение..."
            ref={textareaRef}
          />
          {isSubmitButtonVisible && (
            <div className="message-form__submit-wrapper">
              <button className="message-form__submit" onClick={submitMessage} type="button">
                <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="ic_fluent_send_28_filled" style={{ fill: 'var(--tg-theme-button-color)' }} fillRule="nonzero">
                    <path d="M3.78963301,2.77233335 L24.8609339,12.8499121 C25.4837277,13.1477699 25.7471402,13.8941055 25.4492823,14.5168992 C25.326107,14.7744476 25.1184823,14.9820723 24.8609339,15.1052476 L3.78963301,25.1828263 C3.16683929,25.4806842 2.42050372,25.2172716 2.12264586,24.5944779 C1.99321184,24.3238431 1.96542524,24.015685 2.04435886,23.7262618 L4.15190935,15.9983421 C4.204709,15.8047375 4.36814355,15.6614577 4.56699265,15.634447 L14.7775879,14.2474874 C14.8655834,14.2349166 14.938494,14.177091 14.9721837,14.0981464 L14.9897199,14.0353553 C15.0064567,13.9181981 14.9390703,13.8084248 14.8334007,13.7671556 L14.7775879,13.7525126 L4.57894108,12.3655968 C4.38011873,12.3385589 4.21671819,12.1952832 4.16392965,12.0016992 L2.04435886,4.22889788 C1.8627142,3.56286745 2.25538645,2.87569101 2.92141688,2.69404635 C3.21084015,2.61511273 3.51899823,2.64289932 3.78963301,2.77233335 Z" id="🎨-Color"></path>
                  </g>
                </svg>
              </button>
            </div>
          )}
        </div>
      )}
      <Dialog
        open={isMembershipDialogOpen}
        onClose={() => setIsMembershipDialogOpen(false)}
      >
        <DialogTitle>
          Представьтесь
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Выберите, как вас будут видеть ваши собеседники.
          </DialogContentText>
          <div style={{ display: 'grid', gridTemplateColumns: '70px auto', alignItems: 'end' }}>
            <Button
              variant="text"
              component="label"
              sx={{
                cursor: 'pointer',
                position: 'relative',
                backgroundColor: 'transparent !important',
                display: 'block',
                width: '50px',
                padding: '0px !important',
              }}
            >
              <Avatar src={memberPhotoUrl || chat?.membership.photo_url} sx={{ width: '50px', height: '50px' }} />
              <div style={{
                alignItems: 'center',
                background: 'var(--tg-theme-button-color)',
                color: 'var(--tg-theme-button-text-color)',
                borderRadius: '50px',
                display: 'flex',
                justifyContent: 'center',
                height: '26px',
                padding: '5px',
                position: 'absolute',
                bottom: '-5px',
                right: '10px',
                width: '26px',
              }}>
                <AddAPhotoOutlinedIcon fontSize="20px" />
              </div>
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleMemberPhotoAttach}
              />
            </Button>
            <TextField
              autoFocus
              margin="dense"
              id="member-name"
              label="Имя для отображения"
              type="text"
              fullWidth
              defaultValue={chat?.membership.name}
              inputRef={memberNameFieldRef}
              variant="standard"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsMembershipDialogOpen(false)}>
            Отмена
          </Button>
          <Button onClick={applyMembershipSettings}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isChatDialogOpen}
        onClose={() => setIsChatDialogOpen(false)}
      >
        <DialogTitle>
          Настройки группы
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Выберите название и картинку для группы.
          </DialogContentText>
          <div style={{ display: 'grid', gridTemplateColumns: '70px auto', alignItems: 'end' }}>
            <Button
              variant="text"
              component="label"
              sx={{
                cursor: 'pointer',
                position: 'relative',
                backgroundColor: 'transparent !important',
                display: 'block',
                width: '50px',
                padding: '0px !important',
              }}
            >
              <Avatar src={chatCustomPhotoUrl || chatPhotoUrl || chat?.photo_url} sx={{ width: '50px', height: '50px' }} />
              <div style={{
                alignItems: 'center',
                background: 'var(--tg-theme-button-color)',
                color: 'var(--tg-theme-button-text-color)',
                borderRadius: '50px',
                display: 'flex',
                justifyContent: 'center',
                height: '26px',
                padding: '5px',
                position: 'absolute',
                bottom: '-5px',
                right: '10px',
                width: '26px',
              }}>
                <AddAPhotoOutlinedIcon fontSize="20px" />
              </div>
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleChatPhotoAttach}
              />
            </Button>
            <TextField
              autoFocus
              margin="dense"
              id="member-name"
              label="Название группы"
              type="text"
              fullWidth
              defaultValue={chatName || chat?.name}
              inputRef={chatNameFieldRef}
              variant="standard"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsChatDialogOpen(false)}>
            Отмена
          </Button>
          <Button onClick={applyChatSettings}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChatMessagesPage;
