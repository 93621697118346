import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAPI, useBackAction, useInterval, useTitle } from 'hooks';
import { sha512 } from 'utils/crypto';
import { getRandomUuid } from 'utils/uuid';
import { redirectToSafePage } from 'utils/redirect';
import { hardSignout, selectEncryptionKey, selectUser, signout, updateAuth } from 'slices/auth';
import { refreshChats, selectChats } from 'slices/chats';
import { refreshExternalApps, selectExternalApps } from 'slices/externalApps';
import {
  AppBar,
  Avatar,
  Backdrop,
  Badge,
  Box,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SwipeableDrawer,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  Bookmark as BookmarkIcon,
  Support as SupportIcon,
  GroupRounded as GroupRoundedIcon,
  InstallMobile as InstallMobileIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  PersonAddRounded as PersonAddRoundedIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { Centered, Chats, ChannelsSearch } from 'components';

const fakeItems = new Array(1).fill().map((_, i) => ({
  fake: true,
  id: `${i}`,
  last_message: {
    text: (
      <Skeleton
        variant="text"
        sx={{
          bgcolor: 'rgba(128, 128, 128, 0.2)',
          display: 'inline-block',
          width: Math.random() * 100 + 100,
          height: '25px',
        }}
      />
    )
  },
  last_messages: [],
  name: (
    <Skeleton
      variant="text"
      sx={{
        bgcolor: 'rgba(128, 128, 128, 0.2)',
        display: 'inline-block',
        width: Math.random() * 50 + 100,
        height: '30px',
      }}
    />
  ),
  type: i === 0 ? "personal" : "direct",
}));

const ChatsPage = () => {
  const encryptionKey = useSelector(selectEncryptionKey);
  const dispatch = useDispatch();
  const externalApps = useSelector(selectExternalApps);
  const chats = useSelector(selectChats);
  const user = useSelector(selectUser);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  // const [chats, setChats] = useState(null);

  const { AuthAPI, ChatsAPI, ConnectionsAPI, ExternalAppsAPI, ExternalAppTokensAPI } = useAPI();

  const navigate = useNavigate();

  useTitle('Protected space');

  useBackAction(({ navigate }) => {
    if (isMenuOpen) return setMenuOpen(false);
    if (isSearchOpen) return setSearchOpen(false);

    dispatch(signout(AuthAPI));
    navigate('/', { replace: true });
  });

  useEffect(() => {
    dispatch(refreshExternalApps(ExternalAppsAPI));
  }, []);

  useEffect(() => {
    dispatch(refreshChats(ChatsAPI));
  }, []);

  useInterval(() => {
    dispatch(refreshChats(ChatsAPI));
  }, [], 5000);

  const copyAndNotify = (text) => {
    navigator.clipboard.writeText(text);
    alert('Пригласительная ссылка скопирована');
  };

  const handleConnect = async () => {
    const id = getRandomUuid();
    ConnectionsAPI.create({ id });

    // const url = `https://s3.amazonaws.com/i.i/i.html#/join/${id}`;
    const url = `https://in6.to/#/join/${id}`;
    const title = 'Приглашаю в Protected Space';

    const fallbackMessage = [title, url].join('\n');

    if ('share' in navigator) {
      const dataToShare = { title, url };

      if (navigator.canShare(dataToShare)) {
        try {
          await navigator.share(dataToShare);
        } catch (e) {}
      } else {
        copyAndNotify(fallbackMessage);
      }
    } else {
      copyAndNotify(fallbackMessage);
    }
  };

  const openAccountsVault = () => {
    navigate('/accounts');
  };

  const openFavoritesChat = () => {
    const personalChat = chats?.find((chat) => chat.type === 'personal');
    if (!personalChat) return;
    
    navigate(`/chats/${personalChat.id}`);
  };

  const openSupportChat = () => {
    const supportChat = chats?.find((chat) => chat.type === 'support');
    if (!supportChat) return;
    
    navigate(`/chats/${supportChat.id}`);
  };

  const launchExternalControlledApp = async (domain, path) => {
    const { token } = await ExternalAppTokensAPI.create({ domain });
    const encryptionPassword = await sha512({ domain, encryptionKey });
    const externalAppWindow = window.open(`https://${domain}.in6.to/initializeExternalApp`);
    window.addEventListener("message", async (event) => {
      if (event.origin !== `https://${domain}.in6.to`) return;
      if (event.data === 'readyToInitialize') {
        externalAppWindow.postMessage({
          type: 'dataToRestore',
          path,
          token,
          encryptionPassword
        }, `https://${domain}.in6.to`);
      } else if (event.data === 'danger') {
        dispatch(signout(AuthAPI));
        navigate('/', { replace: true });
        redirectToSafePage();
        window.close(); // TODO: It works only with not changed history
      }
    });
  };

  const hardLogout = () => {
    dispatch(hardSignout(AuthAPI));
    navigate('/', { replace: true });
  };

  const createGroup = () => {
    const id = getRandomUuid();
    ChatsAPI.create({ id, type: 'group' });
    navigate(`/chats/${id}`);
  };

  const installPWA = () => {
    window.App.deferredInstallPrompt?.prompt();
    window.App.deferredInstallPrompt?.userChoice.then((choiceResult) => {
      if (choiceResult.outcome !== 'accepted') return;

      window.App.installed = true;
      window.App.deferredInstallPrompt = null;
    });
  };

  const toggleNotifications = async () => {
    if (user.notify) return dispatch(updateAuth([AuthAPI, { notify: false, firebase_token: null }]));
    if (await Notification.requestPermission() === 'denied') return;
    const firebaseToken = await window.App.getFirebaseToken();
    if (!firebaseToken) return;
    dispatch(updateAuth([AuthAPI, {
      firebase_token: firebaseToken,
      notify: true,
    }]));
  };

  // () => navigate('/connect')

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundImage: 'none',
            backgroundColor: 'var(--tg-theme-bg-color)',
            boxShadow: '0px 0px 1px 0px rgb(0 0 0)',
            color: 'var(--tg-theme-button-text-color)',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              disabled={isMenuOpen}
              edge="start"
              onClick={() => setMenuOpen(true)}
              size="large"
              sx={{ mr: 2 }}
            >
              <Badge color="primary" variant="dot" invisible={true}>
                <MenuIcon />
              </Badge>
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Protected space
            </Typography>
            <IconButton
              color="inherit"
              disabled={isSearchOpen}
              edge="end"
              onClick={() => setSearchOpen(true)}
            >
              <SearchIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <SwipeableDrawer
        anchor='left'
        disableSwipeToOpen={true}
        open={isMenuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'var(--tg-theme-secondary-bg-color)',
            backgroundImage: 'none',
            width: 'min(100%, 300px)',
          },
        }}
      >
        <List
          sx={{
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '& .MuiListItemIcon-root': {
              minWidth: 30,
              ml: -0.5,
              mr: 1,
            },
          }}
        >
          {/*<ListItem disablePadding>
            <ListItemButton>
              <Avatar /> Profile
            </ListItemButton>
          </ListItem>
          <Divider />*/}
          {externalApps.map((externalApp) => (
            <ListItem disablePadding key={externalApp.domain}>
              <ListItemButton onClick={() => launchExternalControlledApp(externalApp.domain, externalApp.path)}>
                <ListItemIcon>
                  <Box
                    component="img"
                    src={externalApp.icon_url}
                    alt={`${externalApp.name} icon`}
                    sx={{ width: 24, height: 24, objectFit: 'contain' }}
                  />
                </ListItemIcon>
                <ListItemText primary={externalApp.name} />
              </ListItemButton>
            </ListItem>  
          ))}
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={openAccountsVault}>
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Хранилище аккаунтов" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={createGroup}>
              <ListItemIcon>
                <GroupRoundedIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Создать группу" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={openFavoritesChat}>
              <ListItemIcon>
                <BookmarkIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Избранное" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={openSupportChat}>
              <ListItemIcon>
                <SupportIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SettingsIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Настройки" />
            </ListItemButton>
          </ListItem>
          {window.App.installable && (
            <ListItem disablePadding disabled={!window.App.deferredInstallPrompt}>
              <ListItemButton onClick={installPWA}>
                <ListItemIcon>
                  <InstallMobileIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="Добавить на главный экран" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton onClick={toggleNotifications}>
              <ListItemIcon>
                <NotificationsIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Уведомления" />
              <Switch checked={user.notify} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={hardLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Выйти" />
            </ListItemButton>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor='right'
        disableSwipeToOpen={true}
        open={isSearchOpen}
        onOpen={() => setSearchOpen(true)}
        onClose={() => setSearchOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'var(--tg-theme-secondary-bg-color)',
            backgroundImage: 'none',
            width: '100%',
          },
        }}
      >
        <ChannelsSearch />
      </SwipeableDrawer>
      <Chats items={chats ? chats.filter((chat) => chat.type !== 'support' || chat.last_message) : fakeItems} />
      <Fab
        aria-label="add"
        onClick={handleConnect}
        sx={{
          backgroundColor: 'var(--tg-theme-button-color) !important',
          color: 'var(--tg-theme-button-text-color)',
          bottom: '20px',
          position: 'fixed',
          right: '20px',
        }}
      >
        <PersonAddRoundedIcon />
      </Fab>
    </>
  )
}

export default ChatsPage;
