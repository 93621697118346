import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import './index.css';
import APIGateway from 'api/gateway'; // FIXME: remove this line
import { store } from 'app/store';
import { wipeOutTraces } from 'utils/storage';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load();

Sentry.init({
  dsn: "https://b63898da2b43ad80d73f1a32b94aef04@o4507923044106240.ingest.de.sentry.io/4507923734659152",
  integrations: [],
});

if (window.self !== window.top) {
  document.body.style.display = 'none !important';
  throw Error('Go fuck yourself with trying to iFrame me!');
}

window.App = {
  installable: false,
  installed: false,
  deferredInstallPrompt: null,
  getFirebaseToken: () => {},
  getFingerprint: async () => {
    const fp = await fpPromise;
    const result = await fp.get();
    return result;
  },
};

try {
  const firebaseApp = initializeApp({
    apiKey: 'AIzaSyAIZARE4At4OYH-cA4-AorY7DNNexyRmBA',
    authDomain: 'protected-space.firebaseapp.com',
    projectId: 'protected-space',
    storageBucket: 'protected-space.appspot.com',
    messagingSenderId: '770227302075',
    appId: '1:770227302075:web:115db416b69c5afaf1839e',
  });
  const firebaseMessaging = isSupported() ? getMessaging(firebaseApp) : null;

  serviceWorkerRegistration.register({
    onRegister: async (registration) => {
      window.App.getFirebaseToken = () => firebaseMessaging && getToken(firebaseMessaging, {
        vapidKey: 'BIuhnH4YvdKG1HwVH_A8oeWbUiBwFVn5izyYQl7iE6oQGABGFN9muFlk_oOFafLuZhMMHxYg0G0LjjXbWGs4qFM',
        serviceWorkerRegistration: registration,
      });

      // onMessage(firebaseMessaging, (payload) => {
      //   console.log('Message received. ', payload);
      // });
    },
    onUpdate: async (registration) => {
      console.log('sw updated', registration)
    },
  });
} catch (e) {
  console.error(e);
}

window.APIGateway = APIGateway; // FIXME: remove this line

// document.addEventListener('blur', () => {
//   document.body.style.opacity = 0;
// });
//
// document.addEventListener('focus', () => {
//   document.body.style.opacity = 1;
// });
//
// document.addEventListener('visibilitychange', () => {
//   if (document.hidden) {
//     document.body.style.opacity = 0;
//   } else {
//     document.body.style.opacity = 1;
//   }
// });

window.onbeforeunload = (e) => wipeOutTraces();
window.addEventListener('beforeunload', window.onbeforeunload, false);

window.addEventListener('devicemotion', (event) => {
  const { x, y, z } = event.acceleration;
  const acceleration = Math.sqrt(x ** 2 + y ** 2 + z ** 2);
  if (acceleration > 0.1 || x > 0.1 || y > 0.1 || z > 0.1) {
    document.dispatchEvent(new CustomEvent('deviceacceleration'));
  } else if (x == null && y == null && z == null) {
    // setInterval(() => {
    //   document.dispatchEvent(new CustomEvent('deviceacceleration'));
    // }, 100);
  }
});

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  window.App.deferredInstallPrompt = e;
  window.App.installable = true;
});

window.addEventListener('appinstalled', (evt) => {
  window.App.installed = true;
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
);
