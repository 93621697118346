import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { Avatar as MuiAvatar, Chip } from '@mui/material';
import { Avatar, ChatIcon, ChatLogo, PublicationTime } from 'components';
import { getChatDisplayName } from 'utils/chats';
import { useAPI } from 'hooks';
import './chat.css';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PinIcon from '@mui/icons-material/PushPin';
import ReplyIcon from '@mui/icons-material/Reply';

const Chat = (chat) => {
  const {
    id,
    last_message,
    last_messages,
    last_read_message_id,
    last_viewed_message_id,
    members_count,
    name,
    pending_connection,
    photo_url,
    type,
  } = chat;

  const [isHidden, setHidden] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { ConnectionsAPI } = useAPI();

  const indexOfLastReadMessage = last_messages.map(({ id }) => id).indexOf(last_read_message_id);
  const lastMessagesCount = last_messages.length;
  let unreadCount;
  if (lastMessagesCount === 0) {
    unreadCount = 0
  } else if (indexOfLastReadMessage === -1 && last_read_message_id != null) {
    unreadCount = Infinity;
  } else {
    unreadCount = lastMessagesCount - (indexOfLastReadMessage + 1);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();

    if (!chat?.pending_connection) return;

    setAnchorEl(e.currentTarget);
  };

  const deleteInvitation = () => {
    if (!chat?.pending_connection) return;

    ConnectionsAPI.destroy(chat.pending_connection);
    setHidden(true);
  };

  return (
    <>
      <Link key={chat.id} to={chat.fake ? '#' : chat.id.toString()}>
        <div className="chat" onContextMenu={handleContextMenu}>
          <div className="chat__logo">
            <ChatLogo {...chat} />
          </div>
          <div className="chat__main">
            <div className="chat__heading">
            <ChatIcon type={chat.type} />
              <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {getChatDisplayName(chat)}
              </span>
            </div>
            <div className="chat__rest">
              <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {type === 'direct' && pending_connection && (
                  'Ожидание присоединения...'
                ) || type === 'group' && pending_connection && (
                  'Добавьте участников в группу...'
                ) || (
                  last_message ? last_message.text : 'Здесь нет ни одного сообщения'
                )}
              </span>
            </div>
          </div>
          <div className="chat__stats">
            <div className="chat__time">
              {last_message && (
                <PublicationTime timestamp={last_message?.created_at} />
              )}
            </div>
            <div className="chat__unread">
              {unreadCount !== 0 && (
                <Chip
                  color="info"
                  label={indexOfLastReadMessage === -1 ? '' : unreadCount}
                  size="small"
                  sx={{
                    backgroundColor: 'var(--tg-theme-button-color) !important',
                    color: 'var(--tg-theme-button-text-color)',
                    fontSize: '12px',
                    minHeight: '24px',
                    minWidth: '24px',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Link>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled onClick={() => console.log('pinChat', id)}>
          <ListItemIcon>
            <PinIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Закрепить</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Удалить приглашение</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Удалить приглашение
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы точно хотите удалить приглашение?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteDialogOpen(false)}
          >
            Отмена
          </Button>
          <Button
            autoFocus
            color="error"
            onClick={deleteInvitation}
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Chat;
//
// https://four-comics-vanish-188-147-68-110.loca.lt/#tgWebAppVersion=6.2&tgWebAppPlatform=android&tgWebAppThemeParams={"bg_color"%3A"%231d2733"%2C"secondary_bg_color"%3A"%23151e27"%2C"text_color"%3A"%23ffffff"%2C"hint_color"%3A"%237d8b99"%2C"link_color"%3A"%235eabe1"%2C"button_color"%3A"%2350a8eb"%2C"button_text_color"%3A"%23ffffff"}
//
// tgWebAppVersion=6.2
// tgWebAppPlatform=android
// tgWebAppThemeParams={
//   "bg_color": "#1d2733",
//   "secondary_bg_color": "#151e27",
//   "text_color": "#ffffff",
//   "hint_color": "#7d8b99",
//   "link_color": "#5eabe1",
//   "button_color": "#50a8eb",
//   "button_text_color": "#ffffff"
// }
