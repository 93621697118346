import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hardSignout, signout } from 'slices/auth';

const initialState = {
  collection: [],
  status: null,
};

export const refreshExternalApps = createAsyncThunk(
  'externalApps/pull',
  async (externalAppsAPI) => {
    const data = await externalAppsAPI.all();

    return data;
  }
);

export const externalAppsSlice = createSlice({
  name: 'externalApps',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(signout.pending, (state) => {
        state.collection = [];
        state.status = null;
      })
      .addCase(hardSignout.pending, (state) => {
        state.collection = [];
        state.status = null;
      })
      .addCase(refreshExternalApps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refreshExternalApps.fulfilled, (state, action) => {
        state.collection = action.payload || [];
      });
  },
});

export const selectExternalApps = (state) => state.externalApps.collection;

export default externalAppsSlice.reducer;
