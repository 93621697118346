import React, { useEffect } from 'react';
import { IdleTimerProvider, DEFAULT_EVENTS } from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  // useParams,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { useAPI, useDeviceMotion, usePageLeave, useTelegramWebApp } from 'hooks';
import LoginPage from 'pages/Login';
import AccountsPage from 'pages/Accounts';
import AddAccountPage from 'pages/AddAccount';
import ChatsPage from 'pages/Chats';
import ChatMessagesPage from 'pages/ChatMessages';
import ConnectPage from 'pages/Connect';
import {
  selectUser,
  signout,
} from 'slices/auth';
import { redirectToSafePage } from 'utils/redirect';
import { Safety } from './Safety';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  const clientInfo = window.ClientInfo;

  const location = useLocation();
  const navigate = useNavigate();
  const telegramWebApp = useTelegramWebApp();

  const dispatch = useDispatch();

  const { AuthAPI } = useAPI();

  // if (!FIXME_storage.getItem('authId')) {
  //   const params = new URLSearchParams(location.search);
  //   const authId = params.get('authId');
  //   FIXME_storage.setItem('authId', authId);
  // }

  useEffect(() => {
    const onBackButtonClick = () => {
      switch (location.pathname) {
        case '/login':
          telegramWebApp.close();
          break;
        default:
          navigate(-1);
      };
    };

    telegramWebApp.expand();
    telegramWebApp.BackButton.onClick(onBackButtonClick);
    telegramWebApp.BackButton.show();
    telegramWebApp.ready();

    return () => {
      telegramWebApp.BackButton.offClick(onBackButtonClick);
    };
  }, [location, navigate, telegramWebApp]);

  if (!clientInfo?.isSafe()) {
    return (
      <Safety
        vpnRequired={clientInfo.isVpnRequired()}
        incognitoRequired={clientInfo.isIncognitoRequired()}
        httpsOnlyRequired={clientInfo.isHttpsOnlyRequired()}
      />
    )
  }

  return (
    <ThemeProvider theme={darkTheme}>
      {/*<CssBaseline />*/}
      <IdleTimerProvider
        events={['deviceacceleration', ...DEFAULT_EVENTS]}
        timeout={1000 * 60}
        onIdle={() => {
          // document.body.style.transition = 'opacity 0.5s, background 0.5s';
          // document.body.style.background = '#000000';
          // document.body.style.pointerEvents = 'none';
          // document.body.style.opacity = 0;
          if (!location.pathname.includes('/login')) {
            dispatch(signout(AuthAPI));
            navigate('/', { replace: true }); // location // TODO: Restore after properly saving state on logout
            telegramWebApp.close();
          }
        }}
        onActive={() => {
          // document.body.style.background = 'var(--tg-theme-secondary-bg-color)';
          // document.body.style.pointerEvents = 'unset';
          // document.body.style.opacity = 1;
        }}
      >
        <Routes>
          <Route path="/login/*" element={<LoginPage />} />
          <Route element={<RequireAuth />}>
            <Route path="accounts">
              <Route index element={<AccountsPage />} />
              <Route path="add" element={<AddAccountPage />} />
            </Route>
            <Route path="chats">
              <Route index element={<ChatsPage />} />
              <Route path=":chatId" element={<ChatMessagesPage />} />
            </Route>
            <Route path="join/:connectionId" element={<ConnectPage />} />
            <Route path="*" element={<Navigate to="chats" replace />} />
          </Route>
        </Routes>
      </IdleTimerProvider>
    </ThemeProvider>
  );
}

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const telegramWebApp = useTelegramWebApp();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const { AuthAPI } = useAPI();

  // TODO: compare behavior on different devices
  // useDeviceMotion((a) => {
  //   dispatch(signout(AuthAPI));
  //   navigate('/', { replace: true }); // location // TODO: Restore after properly saving state on logout
  //   telegramWebApp.close();
  //   redirectToSafePage();
  //   window.close(); // TODO: It works only with not changed history
  // });

  usePageLeave(() => {
    dispatch(signout(AuthAPI));
    navigate('/', { replace: true }); // location // TODO: Restore after properly saving state on logout
  });

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <IdleTimerProvider
      timeout={1000 * 600}
      onIdle={() => {
        dispatch(signout(AuthAPI));
        navigate('/', { replace: true }); // location // TODO: Restore after properly saving state on logout
      }}
    >
      <Outlet />
    </IdleTimerProvider>
  );
}

export default App;
