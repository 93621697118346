import * as ThumbmarkJS from '@thumbmarkjs/thumbmarkjs'
import { getUuid } from 'utils/uuid';

export const getFingerprints = async () => {
  return {
    clientId: getClientVisitorId(),
    fingerprintJS: await getFingerprintJSVisitorId(),
    thumbmarkJS: await getThumbmarkJSVisitorId(),
  };
};

const getClientVisitorId = () => {
  const { ipAddress, ipCountryCode, ipTimeZone, timeZone, incognito } = window.ClientInfo;
  
  return getUuid({ ipAddress, ipCountryCode, ipTimeZone, timeZone, incognito });
};

export const getThumbmarkJSVisitorId = async () => {
  return (await ThumbmarkJS.getFingerprint());
};

export const getFingerprintJSVisitorId = async () => {
  return (await window.App.getFingerprint()).visitorId;
};

export const getFingerprint = async () => {
  const fingerprintData = (await window.App.getFingerprint()).components;

  return fingerprintData;
};

export const getWeakFingerprint = async () => {
  const fingerprintData = await getFingerprint();
  const fingerprint = {
    // canvas: fingerprintData.canvas?.value,
    osCpu: fingerprintData.osCpu?.value,
    colorDepth: fingerprintData.colorDepth?.value,
    deviceMemory: fingerprintData.deviceMemory?.value,
    screenResolution: fingerprintData.screenResolution?.value,
    hardwareConcurrency: fingerprintData.hardwareConcurrency?.value,
    cpuClass: fingerprintData.cpuClass?.value,
    platform: fingerprintData.platform?.value,
    touchSupport: fingerprintData.touchSupport?.value,
    colorGamut: fingerprintData.colorGamut?.value,
    math: fingerprintData.math?.value,
    architecture: fingerprintData.architecture?.value,
    webGlBasics: fingerprintData.webGlBasics?.value,
    // webGlExtensions: fingerprintData.webGlExtensions?.value,
  };

  return fingerprint;
};
