import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Navigate,
  // Outlet,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import './login.css';
import { Centered } from 'components';
import { useAPI, useTitle } from 'hooks';
import {
  selectStatus,
  signin,
} from 'slices/auth';
import PasswordStep from './PasswordStep';
import PictureAuthStep from './PictureAuthStep';
import { Backdrop, CircularProgress } from '@mui/material';

const LoginPage = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectStatus);

  const [password, setPassword] = useState();

  const navigate = useNavigate();
  // const location = useLocation();

  const [from] = useState(/*location.state?.from?.pathname ||*/ '/'); // TODO: Restore after properly saving state on logout

  const { AuthAPI } = useAPI();

  useTitle('Protected');

  const login = ({ password, picture }) => {
    dispatch(signin([AuthAPI, { password, picture }]));
  };

  const handlePictureStepComplete = (picture) => {
    login({ password, picture });
  };

  const handlePasswordStepComplete = (password) => {
    setPassword(password);
    login({ password });
  };

  useEffect(() => {
    if (!password) return;

    if (authStatus === 'rejected') {
      navigate('extra', { replace: false });
    } else if (authStatus === 'ready') {
      navigate(from, { replace: true });
    }
  }, [authStatus, password]);

  return (
    <>
      <Backdrop
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
        open={authStatus === 'loading' || authStatus === 'ready'}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Routes>
        <Route
          path=""
          element={(
            <PasswordStep onComplete={handlePasswordStepComplete} />
          )}
        />
        {password && (
          <Route
            path="extra"
            element={(
              <PictureAuthStep onComplete={handlePictureStepComplete} />
            )}
          />
        )}
        <Route path="*" element={<Navigate to="" replace />} />
      </Routes>
    </>
  );
};

export default LoginPage;
