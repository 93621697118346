import React from 'react';
import SupportIcon from '@mui/icons-material/Support';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import CampaignIcon from '@mui/icons-material/Campaign';

const ChatIcon = ({ size = '20px', type }) => {
  if (type === 'direct') {
    return <PersonRoundedIcon sx={{ height: size, marginRight: '2px', transform: 'translateX(-1px)', width: size }} />;
  } else if (type === 'group') {
    return <PeopleRoundedIcon sx={{ height: size, marginRight: '5px', width: size }} />;
  } else if (type === 'personal') {
    return <BookmarkRoundedIcon sx={{ height: size, transform: 'translateX(-3px)', width: size }} />;
  } else if (type === 'support') {
    return <SupportIcon sx={{ height: size, marginRight: '4px', transform: 'translateX(-1px)', width: size }} />;
  } else if (type === 'channel') {
    return <CampaignIcon sx={{ height: size, transform: 'translateX(-1px)', width: size }} />;
  }

  return <HelpRoundedIcon sx={{ height: size, marginRight: '3px', transform: 'translateX(-1px)', width: size }} />
};

export default ChatIcon;
